@import "global.scss";
@import "themes.scss";

.SidebarView {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  text-align: left;
  padding: 100px 10%;

  .prompt {
    font-weight: $lightFontWeight;
    font-size: 18px;
    margin-bottom: 46px;
    color: $textPrimary;
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    align-content: flex-start;

    .top {
      flex-grow: 1;
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: $nissanDividerColor;
    margin: 50px 0px;
  }
}

.App.brand-infiniti .divider {
  background-color: $infinitiDividerColor;
}

@media screen and (max-width: $tabletMaxWidth) and (min-width: $mobileMaxWidth) {
  .SidebarView .infoContainer {
    flex-direction: row;
  }
  .SidebarView .prompt {
    text-align: center;
  }
  .SidebarView .divider {
    height: auto;
    width: 1px;
    margin: 0px 20px;
  }
}

@media screen and (max-width: $mobileMaxWidth) {
  .SidebarView {
    align-items: center;
    text-align: center;
    padding: 30px 10%;
  }

  .SidebarView .infoContainer {
    align-content: center;
  }
}
