@import "themes.scss";

.RecallView {
  display: flex;
  flex-direction: row;
  height: 60px;
  border-top: 2px solid #ff0000;
  border-bottom: 2px solid #ff0000;
  background-color: #ffe5e5;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 18px;

  .recallCountContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 12px;
  }

  .learnMoreButton {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2px; // for some reason the baseline adjustment is slightly off here
    margin-left: 6px;
    font-size: 12px;
    cursor: pointer;
  }
}

.App.brand-infiniti .RecallView {
  font-family: $infinitiFontFamily;
}
