@import "global.scss";
@import "themes.scss";

$sidebarWidth: 434px;

.loading {
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  font-weight: normal;
  background-color: $nissanSidebarBackgroundColor;
}

.App {
  font-family: $nissanFontFamily;
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .body {
    height: 100%;
    position: relative;
    flex: 1 1 auto;
    background-color: #000000;

    .loading,
    .missing,
    .content {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }

    .missing {
      background-color: $nissanSidebarBackgroundColor;
    }

    .content {
      background-image: $nissanMainBackgroundImage;
      padding-right: $sidebarWidth;
      min-height: 700px;
    }

    .sidebar {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      background-color: $nissanSidebarBackgroundColor;
      max-width: $sidebarWidth;
      min-width: $sidebarWidth;
      width: $sidebarWidth;
      height: 100%;
      overflow-y: scroll;
    }
  }
}

.App.brand-infiniti {
  line-height: 1.15;
  font-family: $infinitiFontFamily;

  .body {
    background-color: $infinitiMainBackgroundColor;

    .content {
      background-image: none;
      background-color: $infinitiMainBackgroundColor;
    }
    .sidebar {
      background-color: $infinitiSidebarBackgroundColor;
    }
  }
}

@media screen and (max-width: $tabletMaxWidth) {
  .App .body .content {
    padding-right: 0px;
    box-sizing: content-box;
  }

  .App .body .sidebar {
    position: relative;
    max-width: 100%;
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: $mobileMaxWidth) {
  .App .body .content {
    min-height: 626px;
  }
}
