@import "global.scss";
@import "themes.scss";

.FeedbackView {
  font-family: $nissanFontFamily;
  border: 1px solid $nissanFeedbackBorderColor;
  padding: 32px;
  margin-bottom: 20px;

  &.sendingFeedback {
    pointer-events: none;
  }

  .feedbackContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .closeButtonTop {
      display: none;
    }

    .improveService {
      font-weight: bold;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .improveServiceIcon {
      margin-right: 10px;
      fill: $nissanTextPrimaryInverted;
    }

    .FeedbackEntryView {
      display: flex;
      flex-direction: column;
      align-items: center;

      .ratingContainer {
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: center;
        padding: 5px;
        margin-bottom: 20px;

        .ratingIcon {
          width: 45px;
          height: 45px;
          margin-right: 12px;
          cursor: pointer;

          circle {
            // eyes
            fill: $nissanFeedbackIconSecondaryColor;
          }
          path {
            // mouth
            stroke: $nissanFeedbackIconSecondaryColor;
          }
          circle:first-child {
            // border
            stroke: $nissanFeedbackIconSecondaryColor;
            fill: transparent;
          }
          &.positive {
            path {
              stroke: $nissanFeedbackIconSecondaryColor;
              fill: $nissanFeedbackIconSecondaryColor;
            }
          }

          &.selected {
            circle {
              fill: #000000;
            }
            path {
              // mouth
              stroke: #000000;
            }
            circle:first-child {
              // border
              stroke: $nissanFeedbackIconPrimaryColor;
              fill: $nissanFeedbackIconPrimaryColor;
            }
          }

          &.positive.selected {
            path {
              // mouth
              fill: #000000;
            }
          }
        }
        :last-child {
          margin-right: 0px;
        }
      }

      .feedbackForm {
        width: 80%;
        margin-bottom: 20px;

        textarea {
          font-family: $nissanFontFamily;
          min-height: 40px;
          min-width: 100%;
          max-width: 100%;
          font-size: 1.1em;
          padding: 10px;
        }
      }
    }

    .FeedbackSubmittedView {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: normal;
      color: $nissanTextSecondaryInverted;

      .thanks {
        font-weight: bold;
        color: $nissanTextPrimaryInverted;
        margin-bottom: 4px;
      }

      .feedbackCheckmark {
        margin-bottom: 20px;
        path {
          fill: $nissanStatusInProgressColor;
        }
      }

      .closeButtonBottom {
        display: none;
      }
    }

    .submitButton {
      width: 30%;
      min-width: 80px;
    }
  }
}

.App.brand-infiniti .FeedbackView {
  font-family: $infinitiFontFamily;
  border-color: $infinitiFeedbackBorderColor;

  .feedbackContent {
    .improveServiceIcon {
      fill: $textPrimary;
    }
  }
  .FeedbackSubmittedView {
    color: $infinitiTextSecondary;

    .feedbackCheckmark {
      path {
        fill: $infinitiStatusInProgressColor;
      }
    }

    .thanks {
      font-weight: bold;
      color: $infinitiTextPrimary;
    }
  }
}

.App.brand-infiniti
  .FeedbackView
  .feedbackContainer
  .feedbackContent
  .FeedbackEntryView
  .ratingIcon {
  circle {
    // eyes
    fill: $infinitiFeedbackIconSecondaryColor;
  }
  path {
    // mouth
    stroke: $infinitiFeedbackIconSecondaryColor;
  }
  circle:first-child {
    // border
    stroke: $infinitiFeedbackIconSecondaryColor;
    fill: transparent;
  }

  &.positive {
    path {
      fill: $infinitiFeedbackIconSecondaryColor;
    }
  }

  &.selected {
    circle {
      fill: $infinitiMainBackgroundColor;
    }
    path {
      // mouth
      stroke: $infinitiMainBackgroundColor;
    }
    circle:first-child {
      // border
      stroke: $infinitiFeedbackIconPrimaryColor;
      fill: $infinitiFeedbackIconPrimaryColor;
    }
  }

  &.positive.selected {
    path {
      fill: $infinitiMainBackgroundColor;
    }
  }
}

@media screen and (max-width: $mobileMaxWidth) {
  .FeedbackView {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    color: $textPrimary;
  }

  .FeedbackView .feedbackContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .feedbackContent {
      padding: 20px;
      margin-left: 20px;
      margin-right: 20px;
      max-width: 600px;
      max-height: 700px;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;

      .closeButtonTop {
        font-weight: bold;
        font-size: 1.1em;
        align-self: flex-end;
        cursor: pointer;
        display: inline-block;
      }
    }
  }

  .FeedbackView .feedbackContainer .feedbackContent .FeedbackEntryView {
    padding-left: 5%;
    padding-right: 5%;

    .improveService {
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 100;

      .improveServiceIcon {
        display: none;
      }
    }
  }

  .App.brand-nissan
    .FeedbackView
    .feedbackContainer
    .feedbackContent
    .FeedbackEntryView {
    .ratingContainer {
      .ratingIcon {
        circle {
          // eyes
          fill: $nissanRed;
        }
        path {
          // mouth
          stroke: $nissanRed;
        }
        circle:first-child {
          // border
          stroke: $nissanRed;
          fill: transparent;
        }

        &.positive {
          path {
            fill: $nissanRed;
          }
        }

        &.selected {
          circle {
            fill: #ffffff;
          }
          path {
            // mouth
            stroke: #ffffff;
          }
          circle:first-child {
            // border
            stroke: $nissanRed;
            fill: $nissanRed;
          }
        }

        &.positive.selected {
          path {
            // mouth
            fill: #ffffff;
          }
        }
      }
    }
  }

  .FeedbackView .feedbackContainer .feedbackContent .FeedbackSubmittedView {
    color: $textSecondary;

    .thanks {
      text-transform: uppercase;
      color: $textSecondary;
      font-weight: 100;
      font-size: 28px;
    }

    .closeButtonBottom {
      display: inline-block;
      margin-top: 40px;
    }
  }
}
