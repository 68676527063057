@import "global.scss";
@import "themes.scss";

.FooterView {
  background-color: #000000;
  text-align: left; // Override the styling we get from App since we want the footers left-aligned. Not centered.
}

.App.brand-infiniti .FooterView {
  background-color: #191919;
}
