$tabletMaxWidth: 1200px;
$mobileMaxWidth: 768px; // IMPORTANT: When changing this value also update the corresponding value in globals.tsx

$brandFontFamily: "Nissan", Arial, Tahoma, sans-serif;
$brandLightFontWeight: 100;
$fontFamily: "Verdana", Arial, Tahoma, sans-serif;

$textPrimary: #000000;
$textSecondary: #343434;

$textPrimaryInverted: #ffffff;
$textSecondaryInverted: #dfdfdf;
$tertiaryTextInverted: #cccccc;
