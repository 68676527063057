@import "./global.scss";
@import "themes.scss";

@font-face {
  font-family: "Nissan";
  src: local("NissanBold"), url(../fonts/nissan-bold.woff) format("woff");
  font-weight: bold;
}
@font-face {
  font-family: "Nissan";
  src: local("NissanLight"), url(../fonts/nissan-light.woff) format("woff");
  font-weight: $lightFontWeight;
}
@font-face {
  font-family: "Nissan";
  src: local("NissanRegular"), url(../fonts/nissan-regular.woff) format("woff");
  font-weight: normal;
}

/* 
Import Nissan Light and Nissan Regular again because we need to embed
these fonts for the global footer and it expects them to be named like this
(which differs from the pattern we use elsewhere in the app).
*/
@font-face {
  font-family: "Nissan Light";
  src: local("NissanLight"), url(../fonts/nissan-light.woff) format("woff");
  font-weight: $lightFontWeight;
}
@font-face {
  font-family: "Nissan Regular";
  src: local("NissanRegular"), url(../fonts/nissan-regular.woff) format("woff");
  font-weight: normal;
}
@font-face {
  font-family: "icon-Nissan-Global";
  src: url(../fonts/nissan-global.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Verdana";
  src: local("Verdana"), url(../fonts/verdana.ttf) format("truetype");
}

@font-face {
  font-family: "Infiniti";
  src: local("InfinitiBold"), url(../fonts/infiniti-bold.ttf) format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Infiniti";
  src: local("InfinitiLight"), url(../fonts/infiniti-light.ttf) format("truetype");
  font-weight: $lightFontWeight;
}
@font-face {
  font-family: "Infiniti";
  src: local("InfinitiRegular"), url(../fonts/infiniti-regular.ttf) format("truetype");
  font-weight: normal;
}

/* 
Import Infiniti Light and Infiniti Regular again because we need to embed
these fonts for the global footer and it expects them to be named like this
(which differs from the pattern we use elsewhere in the app).
*/
@font-face {
  font-family: "Infiniti Bold";
  src: local("InfinitiBold"), url(../fonts/infiniti-bold.ttf) format("truetype");
  font-weight: bold;
}
@font-face {
  font-family: "Infiniti Light";
  src: local("InfinitiLight"), url(../fonts/infiniti-light.ttf) format("truetype");
  font-weight: $lightFontWeight;
}
@font-face {
  font-family: "Infiniti Regular";
  src: local("InfinitiRegular"), url(../fonts/infiniti-regular.ttf) format("truetype");
  font-weight: normal;
}
@font-face {
  font-family: "icon-Infiniti-Global";
  src: url(../fonts/infiniti-global.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
