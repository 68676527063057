@import "global.scss";
@import "themes.scss";

.MainContentView {
  color: $textPrimaryInverted;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  text-align: left;
  padding: 100px 10% 0px 10%;

  .vehicleContainer {
    display: flex;
    flex-direction: row;
  }

  .vehicleImage {
    max-height: 112;
    min-height: 112px;
    height: 112px;
    margin-right: 26px;
  }

  .vehicleTextContainer {
    display: flex;
    flex-direction: column;

    .descriptionText {
      font-size: 14px;
      color: $nissanTextSecondaryInverted;
      margin-bottom: 10px;
    }

    .descriptionDivider {
      background-color: #c3002f;
      width: 50px;
      height: 4px;
      margin-bottom: 12px;
    }

    .pickupTimeTitle {
      font: bold 14px $nissanFontFamily;
    }

    .pickupTime {
      font: $lightFontWeight 36px $nissanFontFamily;
    }
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: $nissanDividerColor;
    margin-top: 50px;
    margin-bottom: 24px;
  }
}

.App.brand-infiniti .MainContentView {
  color: $textPrimary;

  .vehicleTextContainer {
    .descriptionText {
      color: $textPrimary;
    }
    .descriptionDivider {
      visibility: hidden;
    }
    .pickupTimeTitle {
      font-family: $infinitiFontFamily;
    }
    .pickupTime {
      font-family: $infinitiFontFamily;
    }
  }

  .divider {
    background-color: $infinitiDividerColor;
  }
}

@media screen and (max-width: $mobileMaxWidth) {
  .MainContentView .vehicleContainer {
    flex-direction: column;
    align-items: center;
  }

  .MainContentView .vehicleTextContainer {
    align-items: center;
  }

  .MainContentView .vehicleImage {
    margin-bottom: 24px;
  }

  .MainContentView .vehicleTextContainer .descriptionDivider {
    margin: 20px 0px;
  }

  .MainContentView .divider {
    display: none;
  }
}
