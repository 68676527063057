@import "global.scss";
@import "themes.scss";

.SSTButton {
  display: flex;
  flex-direction: row;
  padding: 12px;
  background-color: $nissanRed;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: $nissanTextPrimaryInverted;
  font-weight: bold;
  font-size: 14px;

  &:hover {
    background-color: darken($color: $nissanRed, $amount: 10%);
    cursor: pointer;
  }

  .icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
}

.App.brand-infiniti .SSTButton {
  font-weight: bold;
  font-size: 12px;
  background-color: #ffffff;
  border: 2px solid $textPrimary;
  color: $infinitiTextPrimary;

  &:hover {
    background-color: darken($color: #ffffff, $amount: 10%);
  }

  .icon {
    filter: saturate(0);
  }
}

.App.brand-infiniti .SSTButton {
  font-weight: bold;
  font-size: 12px;
  background-color: #ffffff;
  border: 2px solid $textPrimary;

  &:hover {
    background-color: darken($color: #ffffff, $amount: 10%);
  }

  .icon {
    filter: saturate(0);
  }
}
