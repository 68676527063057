@import "global.scss";
@import "themes.scss";

$ovalDarkenAmount: 10%;

.StatusView {
  margin-top: 24px;
  margin-bottom: 50px;
  font-family: $nissanFontFamily;

  .statusTextContainer {
    display: flex;
    flex-direction: column;

    .statusText {
      font-weight: bold;
      font-size: 12px;
    }

    .statusStep {
      font-weight: normal;
      font-size: 12px;
    }
  }

  .itemContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .mobileOnlyCurrentStatusText {
    width: 100%;
    display: none;
    font-weight: bold;
    font-size: 24px;
  }

  .statusDescription {
    display: flex;
    flex-direction: column;

    .titleContainer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .infoIcon {
        min-width: 20px;
        max-width: 20px;
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }

      .header {
        font-weight: bold;
        font-size: 16px;
      }
    }

    .description {
      margin-top: 16px;
      margin-left: 30px; // info icon width + margin-right
      color: $nissanTextSecondaryInverted;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.StatusItemView {
  display: flex;
  flex-direction: column;
  text-align: left;
  flex-basis: 100%;
  font-family: $nissanFontFamily;

  .progressLine {
    position: relative;
    top: 20px;
    left: 17px;
    width: 100%;
    height: 1px;
    border-width: thin;
    border-top: 2px dashed $nissanStatusInQueueColor;
    &.complete {
      border-top: 2px solid $nissanStatusInProgressColor;
    }
    &.last {
      border-color: transparent;
    }
  }

  .oval {
    width: 34px;
    height: 34px;
    border-radius: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    transform: scale(1);
    transition: 0.25s;
    transition-timing-function: ease-in-out;
    box-sizing: border-box;

    &.complete {
      color: #000000;
      background-color: $nissanStatusCompleteColor;
      &:hover {
        background-color: darken(
          $color: $nissanStatusCompleteColor,
          $amount: $ovalDarkenAmount
        );
      }
    }
    &.in-progress {
      color: #ffffff;
      background-color: $nissanStatusInProgressColor;
      &:hover {
        background-color: darken(
          $color: $nissanStatusInProgressColor,
          $amount: $ovalDarkenAmount
        );
      }
    }
    &.in-queue {
      color: #ffffff;
      background-color: $nissanStatusInQueueColor;
      &:hover {
        background-color: darken(
          $color: $nissanStatusInQueueColor,
          $amount: $ovalDarkenAmount
        );
      }
    }
    &.selected {
      transform: scale(1.2);
      transition: 0.25s;
      transition-timing-function: ease-in-out;
    }
  }

  .title {
    padding-top: 10px;
    width: 75%;
    font-weight: normal;
    font-size: 14px;
    color: $textSecondaryInverted;

    &.complete {
      opacity: 60%;
      font-weight: bold;
    }
    &.in-progress {
      color: $textPrimaryInverted;
      font-weight: bold;
    }
  }
}

.App.brand-infiniti .StatusView {
  font-family: $infinitiFontFamily;
  .statusDescription .description {
    color: $textSecondary;
  }

  .statusDescription .infoIcon {
    filter: invert(1);
  }
}

.App.brand-infiniti .StatusItemView {
  font-family: $infinitiFontFamily;

  .progressLine {
    border-top-style: solid;
    border-top-color: $infinitiDividerColor;
    &.complete {
      border-top-color: $infinitiStatusInProgressColor;
    }
    &.last {
      border-color: transparent;
    }
  }

  .oval {
    &.complete {
      color: $infinitiStatusInProgressColor;
      background-color: $infinitiStatusCompleteColor;
      border: 2px solid $infinitiStatusInProgressColor;
      &:hover {
        background-color: darken(
          $color: $infinitiStatusCompleteColor,
          $amount: $ovalDarkenAmount
        );
      }
    }
    &.in-progress {
      color: #ffffff;
      background-color: $infinitiStatusInProgressColor;
      &:hover {
        background-color: darken(
          $color: $infinitiStatusInProgressColor,
          $amount: $ovalDarkenAmount
        );
      }
    }
    &.in-queue {
      color: $textPrimary;
      background-color: $infinitiStatusInQueueColor;
      border: 1px solid $textPrimary;
      &:hover {
        background-color: darken(
          $color: $infinitiStatusInQueueColor,
          $amount: $ovalDarkenAmount
        );
      }
    }
  }

  .checkmarkIcon {
    filter: invert(0.5) brightness(50%) sepia(1);
  }

  .title {
    color: $textPrimary;
    font-weight: $lightFontWeight;
    &.complete {
      opacity: 60%;
      color: $infinitiStatusInProgressColor;
    }
    &.in-progress {
      color: $textPrimary;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: $mobileMaxWidth) {
  .StatusView .statusTextContainer {
    align-items: center;
  }

  .StatusView .statusTextContainer .statusText {
    font-size: 16px;
  }

  .StatusView .statusTextContainer .statusStep {
    font-size: 16px;
  }

  .StatusView .itemContainer {
    margin-bottom: 30px;
  }

  .StatusView .mobileOnlyCurrentStatusText {
    margin-bottom: 20px;
    display: block;
    text-align: center;
  }

  .StatusView .statusDescription .titleContainer {
    cursor: pointer;
  }

  .StatusItemView .oval {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    font-size: 12px;
    &.selected {
      transform: scale(1.6);
    }
    &.in-queue :first-child {
      display: none;
    }
    &.complete :first-child {
      transform: scale(0.75);
    }
  }

  .StatusItemView .progressLine {
    top: 12px;
    border-top-width: 1px;
    border-top-style: solid;
  }

  .StatusItemView .title {
    display: none;
  }
}
