// Common
$fallbackFonts: Arial, Tahoma, sans-serif;
$lightFontWeight: 100;
$textPrimary: #000000;
$textSecondary: #343434;
$textTertiaryInverted: #cccccc;

// Nissan
$nissanFontFamily: "Nissan", $fallbackFonts;
$verdanaFontFamily: "Verdana", $fallbackFonts;

$nissanTextPrimaryInverted: #ffffff;
$nissanTextSecondaryInverted: #dfdfdf;

$nissanRed: #c3002f;
$nissanMainMidnightBlue: #0f1324;
$nissanMainBackgroundImage: linear-gradient($nissanMainMidnightBlue, #000000);
$nissanSidebarBackgroundColor: #f8f8f8;
$nissanDividerColor: rgba(238, 238, 238, 0.4);

$nissanStatusCompleteColor: #d6ddde;
$nissanStatusInProgressColor: #25b24a;
$nissanStatusInQueueColor: #3e3e3e;

$nissanFeedbackBorderColor: rgba(238, 238, 238, 0.4);
$nissanFeedbackIconPrimaryColor: #ffffff;
$nissanFeedbackIconSecondaryColor: #9a9a9a;

// Infiniti
$infinitiFontFamily: "Infiniti", "Verdana", $fallbackFonts;
$infinitiTextPrimary: #000000;
$infinitiTextSecondary: #343434;

$infinitiMainBackgroundColor: #f9f9f9;
$infinitiSidebarBackgroundColor: #efefef;
$infinitiDividerColor: #e5e5e5;

$infinitiStatusCompleteColor: $infinitiMainBackgroundColor;
$infinitiStatusInProgressColor: #846659;
$infinitiStatusInQueueColor: #ffffff;

$infinitiFeedbackBorderColor: rgba(112, 112, 112, 0.4);
$infinitiFeedbackIconPrimaryColor: rgb(132, 102, 89);
$infinitiFeedbackIconSecondaryColor: #af9890;
