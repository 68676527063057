@import "themes.scss";

.ChevronImage {
  width: 12px;
  height: 12px;

  .fillableShape {
    fill: $nissanRed;

    .SSTButton & {
      fill: #ffffff;
    }
  }
}

.App.brand-infiniti .ChevronImage {
  filter: brightness(0%);
}

.App.brand-infiniti .linkSection .ChevronImage {
  filter: saturate(0%) invert(1);
}
