@import "global.scss";
@import "themes.scss";

.HeaderView {
  background-color: #000000;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  z-index: 10;

  .top {
    height: 30px;
  }

  .bottom {
    height: 70px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;

    .logo {
      width: 100px;
      height: 100px;
      margin-top: -30px;
      margin-left: 114px;
      background-color: $nissanMainMidnightBlue;
    }
  }

  .title {
    font: bold 14px $nissanFontFamily;
    text-align: left;
    color: $textPrimary;
    margin-left: 14px;
  }
}

.App.brand-infiniti .HeaderView {
  box-shadow: none;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  flex-direction: column;

  .top {
    display: none;
  }

  .bottom {
    flex-direction: column;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;

    .logo {
      width: 93px;
      height: 44px;
      margin: 0px;
      background-color: #ffffff;
    }
  }

  .title {
    display: none;
  }
}

@media screen and (max-width: $tabletMaxWidth) {
  .HeaderView .bottom .logo {
    width: 50px;
    height: 50px;
    margin-top: 0px;
    margin-left: 0px;
  }

  .HeaderView .bottom {
    height: 50px;
  }
}
