@import "global.scss";
@import "themes.scss";

.SidebarButton {
  display: flex;
  flex-direction: row;
  padding: 12px;
  background-color: #eeeeee;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: $textPrimary;
  font-weight: normal;
  font-size: 14px;

  &:hover {
    background-color: darken($color: #eeeeee, $amount: 10%);
  }

  .icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
}

.App.brand-infiniti .SidebarButton {
  font-weight: bold;
  font-size: 12px;
  background-color: #ffffff;
  border: 2px solid $textPrimary;

  &:hover {
    background-color: darken($color: #ffffff, $amount: 10%);
  }

  .icon {
    filter: saturate(0);
  }
}

.App.brand-infiniti .SidebarButton {
  font-weight: bold;
  font-size: 12px;
  background-color: #ffffff;
  border: 2px solid $textPrimary;

  &:hover {
    background-color: darken($color: #ffffff, $amount: 10%);
  }

  .icon {
    filter: saturate(0);
  }
}
