@import "./global.scss";

.DealerHoursView {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  .headerContainer {
    font: bold 15px $brandFontFamily;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .entry {
    color: $textSecondary;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

.App.brand-nissan .DealerHoursView {
  .headerContainer {
    font-family: $brandFontFamily;
  }
}

@media screen and (max-width: $tabletMaxWidth) and (min-width: $mobileMaxWidth) {
  .DealerHoursView {
    flex-grow: 1;
  }
}

@media screen and (max-width: $mobileMaxWidth) {
  .DealerHoursView .headerContainer {
    cursor: pointer;

    .text {
      margin-right: 12px;
    }
  }
}
